<template>
  <div>
    <el-row>
      <el-form inline :model="purchaseListCondition">
        <el-form-item
          label="编号/名称"
          class="formItemBoxStyle"
          prop="taskCodeName"
        >
          <el-input
            style="width: 200px"
            v-model="purchaseListCondition.taskCodeName"
            placeholder="请输入"
            @input="handleQueryTable"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="状态"
          class="formItemBoxStyle"
          prop="taskSubmitStatus"
        >
          <el-select
            style="width: 200px"
            v-model="purchaseListCondition.taskSubmitStatus"
            placeholder="请输入"
            @change="handleQueryTable"
          >
            <el-option label="全部" :value="0"></el-option>
            <el-option label="待提交" :value="1"></el-option>
            <el-option label="已提交" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="handleResetTable">重置</el-button>
          <el-button type="primary" @click="handleQueryTable">查询</el-button>
        </el-form-item>
      </el-form>
    </el-row>
    <!-- 表格区域 -->
    <el-row>
      <el-table
        v-loading="loadData"
        :data="purchaseListData"
        :header-cell-style="{
          'text-align': 'center',
          backgroundColor: '#dfe6ec',
        }"
        :cell-style="{ 'text-align': 'center' }"
      >
        <el-table-column label="任务编号" prop="planCode"></el-table-column>
        <el-table-column label="任务名称" prop="planName"></el-table-column>
        <el-table-column label="任务时间">
          <template slot-scope="scope">
            {{ handleDateFormate(scope.row.startTime) }}~{{
              handleDateFormate(scope.row.endTime)
            }}
          </template>
        </el-table-column>
        <el-table-column label="发布人" prop="publisherName"></el-table-column>
        <el-table-column label="状态">
          <template slot-scope="scoped">
            <span>{{
              scoped.row.taskSubmitStatus == 1 ? "待提交" : "已提交"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scoped">
            <span
              @click="handleGoToPurchaseDetailPage(scoped.row)"
              style="color: #00f; cursor: pointer; text-decoration: underline"
              >{{
                scoped.row.taskSubmitStatus == 1 ? "生成采购清单" : "查看"
              }}</span
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <!-- 分页组件 -->
    <el-row style="margin-top: 20px">
      <el-pagination
        @size-change="handleSizeChangeTableMsgPage"
        @current-change="handleCurrentChangeTableMsgPage"
        :current-page="tablePageInfo.pageIndex"
        :page-sizes="[10, 20, 50, 100, 150, 200]"
        :page-size="tablePageInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tablePageInfo.pageTotal"
      ></el-pagination>
    </el-row>
  </div>
</template>

<script>
import { getSchoolPurchaseTaskListApi } from "@/api/materialManage/purchase.js";
import { formatDate } from "@/utils/util";
import { getStore } from '@/utils/token.js';
export default {
  data() {
    return {
      purchaseListCondition: {
        taskCodeName: "",
        taskSubmitStatus: 0,
        sysOrgSchoolId:0,
      },
      purchaseListData: [
      ],
      tablePageInfo: {
        pageIndex: 1,
        pageSize: 10,
        pageTotal: 2,
      },
      sysOrgSchoolId:0,

      loadData: true
    };
  },
  created() {
    this.sysOrgSchoolId = Number(getStore("sysOrgSchoolId"))
    this.purchaseListCondition.sysOrgSchoolId=this.sysOrgSchoolId
    this.fetchPurchaseTableList();
  },
  methods: {
    fetchPurchaseTableList() {
      getSchoolPurchaseTaskListApi(
        this.tablePageInfo.pageIndex,
        this.tablePageInfo.pageSize,
        this.purchaseListCondition
      ).then((res) => {
        if (res.success) {
          this.purchaseListData = res.data;
          this.tablePageInfo.pageTotal = res.total;
          this.loadData = false
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    handleQueryTable() {
      this.tablePageInfo.pageIndex = 1;
      this.tablePageInfo.pageSize = 10;
      this.fetchPurchaseTableList();
    },
    handleResetTable() {
      this.purchaseListCondition = {
        taskCodeName: "",
        taskSubmitStatus: 0,
        sysOrgSchoolId: this.sysOrgSchoolId,
      };
      this.handleQueryTable();
    },
    // 跳转至详情页
    handleGoToPurchaseDetailPage(row) {
      this.$router.push({
        path: `/materialManage/purchaseDetail?
        purchaseInfo=${JSON.stringify(row)}`,
      });
    },
    // 日期转换
    handleDateFormate(timestamp) {
      let time = new Date(timestamp);
      return formatDate(time, "yyyy/MM/dd");
    },
    handleSizeChangeTableMsgPage(val){
      this.tablePageInfo.pageIndex = 1;
      this.tablePageInfo.pageSize = val;
      this.fetchPurchaseTableList();
    },
    handleCurrentChangeTableMsgPage(val){
      this.tablePageInfo.pageIndex = val;
      this.fetchPurchaseTableList();
    }
  },
};
</script>

<style></style>
